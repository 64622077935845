.clear:after {
  content: "";
  display: block;
  clear: both;
}
.clear {
  zoom: 1;
}
.fl {
  float: left;
}
.opacity0 {
  opacity: 0;
}
.img-responsive {
  width: 100%;
  height: 100%;
}
.form-container {
  width: 100%;
  padding-top: 0.3rem;
  box-sizing: border-box;
}
.form-container .form-item {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  width: 100%;
}
.form-container .form-item .label {
  width: 18%;
  margin-left: 0.3rem;
  margin-right: 0.2rem;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  letter-spacing: -1px;
}
.form-container .form-item .info {
  flex: 1;
  /* padding: .10rem .20rem; */
  font-size: 0.32rem;
  color: #000;
  line-height: 0.44rem;
}
.form-container .question-wrap {
  background: #fff;
}
.form-container .question-wrap .label {
  width: 100%;
  padding: 0.28rem 0.3rem;
  box-sizing: border-box;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
  letter-spacing: -1px;
}
.form-container .question-wrap .info {
  font-size: 0.32rem;
  color: #000;
  line-height: 0.44rem;
}
.form-container .question-wrap textarea {
  width: 100%;
  height: 3.2rem;
  padding: 0 0.3rem 0.28rem;
  border: none;
  box-sizing: border-box;
}
.form-container .question-wrap textarea::-webkit-input-placeholder {
  color: #ccc;
}
.photo-upload-block {
  width: 100%;
  padding-left: 0.3rem;
  border-top: 0.1rem solid #f8f8f8;
  background: #fff;
  box-sizing: border-box;
}
.photo-upload-block .label {
  width: 100%;
  padding: 0.2rem 0;
  border-bottom: 1px solid #e8e8e8;
  font-size: 0.32rem;
  color: #333;
  line-height: 0.44rem;
}
.photo-upload-block .upload-wrap {
  width: 100%;
  padding: 0.32rem 0.12rem 0.12rem 0;
  box-sizing: border-box;
}
.photo-upload-block .upload-wrap .img-list {
  width: 100%;
}
.photo-upload-block .upload-wrap .img-list li {
  position: relative;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  width: 1.28rem;
  height: 1.28rem;
}
.photo-upload-block .upload-wrap .img-list .icon-del {
  position: absolute;
  top: 0;
  right: 0;
  margin-top: -0.18rem;
  display: inline-block;
  width: 0.36rem;
  height: 0.36rem;
  font-size: 14px;
  color: #fff;
  line-height: 0.36rem;
  text-align: center;
  border-radius: 0.18rem;
  background: red;
}
.photo-upload-block .upload-wrap .upload-btn {
  position: relative;
  margin-bottom: 0.2rem;
  width: 1.28rem;
  height: 1.28rem;
  border-radius: 4px;
}
#upload_img {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
}
/* 底部按钮 */
.btn-bottom {
  margin: 1rem auto 0;
  width: calc(100% - 0.6rem);
  height: 0.98rem;
  border-radius: 8px;
  font-size: 0.36rem;
  color: #fff;
  line-height: 0.52rem;
  background: #417df2;
}
